
<template>
    <form class="">
      <div class="row g-3">
        <div class="col-12">
          <input type="text" v-model="data.username" name="username" class="form-control" placeholder="Username"
          :class="{ 'is-invalid': v$.data.username.$error }">
          
          <div v-if="v$.data.username.$error"
            class="invalid-feedback">
            <span v-if="v$.data.username.required.$message">
              {{ v$.data.username.required.$message }}</span>
          </div>
        </div>
        <div class="col-12">
          <input type="email" v-model="data.email" class="form-control"
          name="email" placeholder="Enter email" :class="{ 'is-invalid': v$.data.email.$error }">
          
          <div v-for="(item, index) in v$.data.email.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>      
        </div>
        <div class="col-12">
          <select v-model="data.country_id" name="country"
          class="form-select" :class="{ 'is-invalid': v$.data.country_id.$error  }">
            <option value="" disabled>--Select country--</option>
            <option
                v-for="(country, conIndex) in countries"
                :value="country.id"
                :key="conIndex" > {{country.name}} </option>
          </select>
          <div v-if="v$.data.country_id.$error"
            class="invalid-feedback">
            <span v-if="v$.data.country_id.required.$message">
              {{ v$.data.country_id.required.$message }}</span>
          </div>
        </div>
        <div class="col-12">
            <multiselect 
              mode="tags"
              :close-on-select="false"
              searchable
              valueProp="id"
              trackBy="name" label="name"
              :options="categories" 
              v-model="data.categories" placeholder="--select category--"></multiselect>
        </div>
        <div class="col-12">
          <input type="password" v-model="data.password" class="form-control"
          name="password" placeholder="Enter your password" :class="{ 'is-invalid': v$.data.password.$error }">
          <div v-for="(item, index) in v$.data.password.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div class="col-12">
          <p class="small my-3">By clicking Sign up, you agree to our <a class="text-primary" target="_blank" :href="absoluteUrl('/terms-conditions')"> terms of service.</a></p>
          <div class="d-grid">
            <button @click.prevent="submitData()" class="btn btn-primary"  type="button">
              Sign up
            </button>
          </div>
        </div>
      </div>
    </form>
</template>
<script>
import { required, email, minLength, alphaNum, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'

export default {
  name: 'user-signup-form',
  setup() {
    return { v$: useVuelidate() };
  },
  components:{
    Multiselect
  },
  data() {
    return {
      data: {
        username: "",
        email: "",
        country_id: "",
        password: "",
        categories: [],
        role: "worker"
      },
      passwordType: "password",
    }
  },
  validations: {
    data:{
      username: {
        alphaNum: alphaNum,
        required: helpers.withMessage("Username is invalid", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      country_id: {
        required: helpers.withMessage("Country is required", required),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
        minLength: helpers.withMessage("minimum of 6 characters", minLength(6))
      },
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    categories(){
      return this.$store.state.categories;
    }
  },
  methods: {
    passwordVisibility(){
      this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.v$.$validate().then(result =>{
        if (!result) return;
        this.$store.dispatch("changeLoaderValue", true)
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$http.post(this.absoluteUrl("/api/register"), formData)
        .then((response)=> {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.takeAction(response.data.data)
          }
        })
      });
    },
    takeAction(data){
      this.resetForm()
      this.$emit("takeAction", {...data})
    },
    resetForm(){
      this.data = {
        username: "",
        email: "",
        country_id: "",
        password: "",
        categories: [],
        role: "worker"
      }
      this.v$.$reset()
    },
  },
  created(){
    this.$store.dispatch("fetchCategories")
  }
}
</script>

<style lang="scss">
select:invalid{
  color: #869ab8
}
</style>
